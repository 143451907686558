import React, { Component } from "react";
import { avatar1, avatar2 } from "../../utils/";
import { graphql, Link, StaticQuery } from "gatsby";
import styled from "styled-components";

class CareerCard extends Component {
  constructor() {
    super();
    this.state = {
      active: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ active: !this.state.active });
    // alert("Form submitted");
  }
  render() {
    return (
      <CareerWrapper>
        <StaticQuery
          query={CareersDataQUERY}
          render={data => {
            const careers = data.careersData.edges;
            return careers.map(career => {
              const title = career.node.title || career.node.slug;
              return (
                <div className="container" key={career.node.slug}>
                  <div className="avatar-flip">
                    <img src={avatar1} height={150} width={150} alt="img1" />
                    <img src={avatar2} height={150} width={150} alt="img2" />
                  </div>
                  {/* Here the slug page already generated for all the careerCards at the the build time, by reading the data from gatsby-node.js file that generates the slug of cards based on the CareerTemplate.js file */}
                  <Link to={career.node.slug}>
                    <h2>{title}</h2>
                    <h4>{"Published: " + career.node.publishDate}</h4>
                    <p>
                      {career.node.shortDescription.shortDescription}
                      <span style={{ color: "#1476f2", fontSize: "0.9rem" }}>
                        Read more ->
                      </span>
                    </p>
                    <div className="divider" />
                    <ul>
                      {career.node.techList.map((icon, index) => {
                        return <li key={index}> {icon}</li>;
                      })}
                    </ul>
                  </Link>
                </div>
              );
            });
          }}
        />
        ;
      </CareerWrapper>
    );
  }
}

const CareersDataQUERY = graphql`
  {
    careersData: allContentfulCareerCards {
      edges {
        node {
          index
          title
          publishDate(formatString: "DD MMMM, YYYY")
          shortDescription {
            shortDescription
          }
          techList
          slug
        }
      }
    }
  }
`;

/**************************************/
//       ServicesBodyWrapper SC
/**************************************/
const CareerWrapper = styled.div`
  background-color: #f0f0f0;
  font-family: roboto;
  padding: 1rem 0rem;
  /* display: flex; */
  /*justify-content: center;
  align-items: center; */
  ul {
    padding-top: 0.8rem;

    display: flex;
    justify-content: space-evenly;
    color: #aaa;
  }
  .container {
    width: 100%;
    margin: 120px auto 120px;
    background-color: #fff;
    padding: 0 20px 20px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
    text-align: center;
    transition: width 0.5s linear;
  }
  .container:hover .avatar-flip {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
  }
  .container:hover .avatar-flip img:first-child {
    opacity: 0;
  }
  .container:hover .avatar-flip img:last-child {
    opacity: 1;
  }
  .avatar-flip {
    border-radius: 100px;
    overflow: hidden;
    height: 150px;
    width: 150px;
    position: relative;
    margin: auto;
    top: -60px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    box-shadow: 0 0 0 13px #f0f0f0;
    -webkit-box-shadow: 0 0 0 13px #f0f0f0;
    -moz-box-shadow: 0 0 0 13px #f0f0f0;
  }
  .avatar-flip img {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
  }
  .avatar-flip img:first-child {
    z-index: 1;
  }
  .avatar-flip img:last-child {
    z-index: 0;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    opacity: 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
  }
  h4 {
    font-size: 13px;
    color: #00baff;
    letter-spacing: 1px;
    margin-bottom: 25px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
    color: #666;
  }

  @media (max-width: 767px) {
    display: block;
    /* flex-flow: column; */
    /* width: 100%; */
    .container {
      width: 90%;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    /* flex wrap By default, flex items will all try to fit onto one line. You can change that and allow the items to wrap as needed with this property. */
    flex-wrap: wrap;
    flex-direction: row;
    /* to center the cards instead of align to left in case we have only one card */
    justify-content: center;
    align-items: center;
    .container {
      margin: 5rem 2rem;
      width: 45%;
    }
  }
`;

export default CareerCard;
