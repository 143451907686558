import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { CareersHeader, BannerDefault, Section, Title } from "../utils";
import { graphql, StaticQuery } from "gatsby";
import CareerCard from "../components/07-careers/CareerCard";

const CareersPage = ({
  dhTitle,
  dhSubTitle,
  dsectionTitle1,
  dsectionSubTitle1
}) => (
  <Layout>
    <SEO
      title="XBound"
      keywords={[
        `smarta kontrakt sverige svenskt företag`,
        ` svenskt företag blockchain utveckling sverige`,
        `Blockchain development smarta kontrakt och blockchain utveckling`,
        `Smart Contract development `,
        `Ethereum Development sverige eterumutveckling `,
        `Solidity Development svenskt företag `,
        `Hyperledger Development Sweden`,
        `ICO STO Development Sweden Stockholm Malmo`,
        `Smart contract Audit Sweden smart kontraktskontroll`,
        `Blockchain Consulting and Development Sweden Stockholm Malmo`,
        `Full-Stack Development Sweden Stockholm Malmo`,
        `svensk blockchain utveckling sverige`,
        `svensk blockchain smart kontraktsutveckling`,
        `Full-Stack Development services sverige`,
        `blockchain company Sweden`,
        `ERC20 Ethereum Token Development Sweden`,
        `Swedish Ethereum Development ICO`,
        `gatsby smarta kontrakt och blockchain utveckling sverige`,
        `react sverige svenskt företag `,
        `Nodejs sverige svenskt företag `,
        `JAMStack Swedish Sweden Stockholm Malmo`,
        `Gatsby Website development Sweden`,
        `React Redux Graphql Front developer svenskt företag `,
        `React Redux Graphql Backend development Sweden Stockholm Malmo`,
        `Cryptocurency development Sweden`,
        `Wallet development Cryptocurency Smart Contract`,
        `Website development Sweden`,
        `Website blog hemsida utveckling Sweden Stockholm Malmo`,
        `Website blog hemsida utveckling svenskt företag`
      ]}
    />
    <StaticQuery
      query={CarrerDataQUERY}
      render={data => {
        const careerPage = data.careerPageDate.edges[0];
        const {
          hTitle,
          hSubTitle,
          sectionTitle1,
          sectionSubTitle1
        } = careerPage.node;
        return (
          <div>
            <CareersHeader>
              <BannerDefault
                title={hTitle || dhTitle}
                subtitle={hSubTitle.hSubTitle || dhSubTitle}
              />
            </CareersHeader>
            <Section>
              <Title
                title={sectionTitle1 || dsectionTitle1}
                message={sectionSubTitle1.sectionSubTitle1 || dsectionSubTitle1}
              />
            </Section>
            <CareerCard />
          </div>
        );
      }}
    />
  </Layout>
);

const CarrerDataQUERY = graphql`
  {
    careerPageDate: allContentfulCareerPage {
      edges {
        node {
          hTitle
          hSubTitle {
            hSubTitle
          }
          sectionTitle1
          sectionSubTitle1 {
            sectionSubTitle1
          }
        }
      }
    }
  }
`;

CareersPage.defaultProps = {
  dhTitle: "Careers Page_",
  dhSubTitle:
    "Do you want to be part of the blockchain's revolution? Have a look at the current openings_",
  dsectionTitle1: "We are continuously expanding_",
  dsectionSubTitle1:
    "Would you like to join our talented team at XBound? We are continuously looking for kind, talented and skillful professionals to join our multicultural and diverse team_"
};

export default CareersPage;
